.equityOverview{
  --c_improvement-text: var(--c_green);
  --c_label-sub-text: var(--c_text-secondary);
  --c_info: var(--c_green-96);
  --c_info-2: var(--c_gold-97);
  --c_greyed-out-text: var(--c_disabled);
  --c_poor-data-text: var(--c_orange-63);
  --c_decline-text: var(--c_red-55);
  .financialOverview{
    display: flex;
    flex-direction: column;
    align-items: center;
    .goodData{
      color: var(--c_improvement-text)
    }
    .badData{
      color: var(--c_poor-data-text)
    }
    .chartContainer{
      align-items: center;
      display: flex;
      flex-direction: column;
      position: relative;
      .equityLabels{
        position: absolute;
        text-align: center;
        top: 25%;

        font-size: 2.25rem;

        .line {
          margin-bottom: 1rem;
        }

        .label {
          font-size: 1rem;
          color: var(--c_label-sub-text);
        }
        .lowData{
          color: var(--c_greyed-out-text)
        }
        .missingData{
          color: var(--c_poor-data-text)
        }
      }
    }
    .chartSideInfo{
      >div{
        position: relative;
        background: var(--c_info);
        border-radius: 1.5rem;
        padding: 1rem;
        padding-right: 2rem;
        margin: 1rem 1rem 1rem 1rem;
        .toolTip{
          top: 0;
        }
        svg{
          display: inline;
          margin: 0.5rem;
        }
        .value{
          color: var(--c_improvement-text);
          font-size: 1.8rem;
          &.badData{
            color: var(--c_poor-data-text)
          }
          &.missingData{
            color: var(--c_greyed-out-text);
          }
        }
        &.negativeVal{
          background-color: var(--c_info-2);
          .value{
            color: var(--c_decline-text);
            &.missingData{
              color: var(--c_greyed-out-text)
            }
            svg{
              transform: scaleY(-1);
            }
          }
        }
        &.poorValue{
          background-color: var(--c_info-2);
        }
        &.missingData{
          background-color: var(--c_yellow-98);
        }
      }
    }
  }
  .lowDataNotification{
    margin-top: 3rem;
    .noAntdh2{
      font-size: 1.2rem;
      margin-bottom: 0;
    }
    p{
      font-size: 0.8rem;
      color: var(--c_grey-out-text);
    } 
    .arrowLinkEquity{
      color: var(--c_improvement-text);
    }
    a:hover.arrowLinkEquity{
      color: var(--c_green-23);
    }
  }
  @media (min-width: theme('screens.md')) {
    .financialOverview{
      flex-direction: row;
      .chartSideInfo{
        margin: 1rem 0 1rem 1rem;
      }
    }
  }
}
