.financialDetails {
  position: relative;
  h2 {
    &.mainHeader {
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 0.25rem;
      font-family: var();
    }
  }
  p {
    font-size: 1rem;
    color: var(--c_neutral-47);
  }
  .mortgageNumber {
    color: var(--c_neutral-47);
    padding-bottom: 0.5rem;
  }
  .detailsContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    .financialDescription {
      > div {
        margin-bottom: 0.5rem;
      }
    }
    .value {
      text-align: right;
      .unknown {
        color: var(--c_orange-63);
      }
      > div {
        margin-bottom: 0.5rem;
      }
    }
  }
  .alert {
    width: calc(100% + 2rem);
    right: 1rem;
    color: var(--c_black);
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    .alertGood {
      color: var(--c_black);
    }
  }
  @media (min-width: theme('screens.md')) {
    .alert {
      right: 2rem;
      width: calc(100% + 4rem);
    }
  }
}
