.needMoreDetailsCard {
  position: relative;
  background: var(--c_green-44);
  color: var(--c_white);
  border-radius: 1.5rem 1.5rem 2rem 2rem;

  .body {
    padding: 0.75rem 1rem;
    position: relative;
    letter-spacing: -0.0125rem;
    overflow: hidden;
    h2 {
      color: var(--c_white);
      font-size: 1.2rem;
    }
    p {
      opacity: 0.64;
      color: var(--c_white);
      font-size: 0.8rem;
    }
  }
  .patterns {
    border-radius: 1.5rem;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    svg.lines {
      width: 100%;
      object-fit: cover;
      color: var(--c_black);  
      opacity: 0.12;
      transform: translateY(-50%)
    }
  }
  @media (min-width: theme('screens.lg')) {
    .body{
      padding: 1.5rem;
    }
  }
} 
