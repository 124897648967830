.mortgageAnalysis{
  position: relative;
  div.toolTip{
    top: 0;
    right: 0;
  }
  .savings{
    color: var(--c_green-44);
    font-size: 1.6rem;
  }
  .analysisBreakdown{
    .calculation{
      padding-top: 1em;
      display: flex;
      flex-wrap: wrap;
      >div{
        flex: 1 1 100%;
        display: flex;
        justify-content: space-evenly;
        font-size: 1.6rem;
        >div{
          flex: 1 1 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          .description{
            color: var(--c_disabled);
            font-size: 0.5em;
          }
          .badRate{
            color: var(--c_orange-63);
          }
        }
        div.operator{
          padding-top: 0.75em;
        }
      }
    }
  }
  .interestRateSavings{
    display: flex;
    flex-direction: column;
    align-items: center;
    >div{
      background-color: var(--c_green-96);
      border-radius: 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem;
      margin: 1rem;
      .description{
        font-size: 0.9rem;
        text-align: center;
      }
    }
  }
  .alert{
    right: 1rem;
    width: calc(100% + 2rem);
  }
  .arrowLink{
    padding-top: 0.5rem;
  }
  @media (min-width: theme('screens.sm')) {
    .analysisBreakdown{
      .calculation{
        div.operator{
          padding-top: 0.75em;
        }
        .leftHandSide{
          flex: 4 4 auto;
        }
        >div.operator{
          flex: 1 1 auto;
        }
        .rightHandSide{
          flex: 1 1 auto;
        }
      }
    }
  }
  @media (min-width: theme('screens.md')) {
    div.toolTip{
      top: -1rem;
      right: -1rem;
    }
    .alert{
      right: 2rem;
      width: calc(100% + 4rem);
    }
  }
}
