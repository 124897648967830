.footerContainer {
  background: var(--c_white);
  width: 100%;
}

.footer {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1200px;
  padding: 2rem 1rem;

  .colophon {
    margin-top: 2rem;
    color: var(--c_text-secondary);

    .logo {
      align-self: flex-end;

      width: 83px;
      height: 34px;

      color: var(--c_text);
    }

    p {
      margin: 0;
    }
  }
  .social {
    align-items: center;
    display: flex;

    a {
      color: var(--c_green);
      margin-right: 1rem;

      &:hover {
        color: var(--c_green-23);
        text-decoration: underline;
      }

      svg {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }
}

@media (min-width: theme('screens.sm')) {
  .footer {
    flex-direction: row;

    .colophon {
      margin-top: 0;
    }
    .social a {
      margin-right: 0;
      margin-left: 0.5rem;
    }
  }
}
@media (min-width: theme('screens.xl')) {
  .footer {
    padding: 3rem 0;
  }
}
