.input{
  label{
    margin-bottom: 0.75rem;
  }

  .inputTooltip {
    display: inline;
    svg {
      display: inline;
      color: var(--c_neutral-86);
      fill: var(--c_neutral-86);
      margin-left: .25rem;
    }
  }

  input{
    display: block;
  }
}
