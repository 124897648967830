.pieChart {
  
  --c_base: var(--c_neutral-86);
  --c_arc-1: var(--c_green-44);
  --c_arc-2: var(--c_green-60);
  --c_arc-disabled-1: var(--c_neutral-65);
  --c_arc-disabled-2: var(--c_neutral-85);
  --c_position-ball: var(--c_green-23);
  --c_position-ball-disabled: var(--c_neutral-54);

  .baseCircle {
    stroke: var(--c_base);
  }

  .chartArc {
    width: 100%;
    height: 100%;
    background: var(--c_arc-2);
  }

  .positionBall {
    fill: var(--c_position-ball);
  }

  &.disabled {
    .chartArc {
      background: var(--c_arc-disabled-2);
    }
    .positionBall {
      fill: var(--c_position-ball-disabled);
    }
  } 
}
