.financialAnalysis {
  .title {
    font-size: 1.5rem;
    font-family: var(--f_open-sans);
  }
  .titleDescription {
    font-size: 1rem;
    font-family: var(--f_open-sans);
  }
  .content {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    svg.circles {
      width: 100%;
      transform: translateY(-44%);
    }
    .editDetails {
      margin-bottom: 3rem;
      .card {
        margin-bottom: 3rem;
      }
    }

    svg.waves {
      width: 200%;

      transform: translate(-25%, -50%);
    }

    svg.lines {
      width: 100%;
      transform: translateY(-50%);
    }

    .noMortgage {
      color: var(--c_neutral-47);
    }

    .addMortgageContainer {
      margin-top: 1.5rem;
      padding-top: 1.5rem;
      border-top: 1px solid var(--c_neutral-86);
      display: flex;
      gap: 1rem;

      button {
        padding-top: 0.5rem;
        padding-bottom: 0.625rem;
        background: var(--c_blue-59);
        color: var(--c_white);
        border: 0;
        font-size: 1rem;
      }
      .addMortgageLarge {
        display: none;
      }
    }

    .financeHubRedirect {
      margin-bottom: 3rem;
      h2 {
        font-size: 1.5rem;
        padding-bottom: 1rem;
        border-bottom: 3px solid var(--c_black);
        margin-bottom: 1rem;
      }
      p {
        font-size: 1.25rem;
        color: var(--c_neutral-4);
        font-family: var(--f_optima);
        padding-bottom: 1rem;
      }
    }

    .propertyAndPrivacyContainer {
      width: 100%;
      position: relative;
      margin-bottom: 1rem;
      .propertyAndPrivacy {
        .privacy {
          display: none;
        }
      }
    }
    .helpBox {
      grid-row: 1;

      display: none; // Not visible in mobile
      flex-direction: column;
      align-items: center;
      width: 100%; 
      border-radius: 1.5rem;
  
      margin-bottom: 3rem;
      margin-top: 3rem;
      padding: 2rem;
  
      font-size: 1.125rem;
      text-align: center;

      background: var(--c_green-95);

      .advisorCta {
  
        border-radius: 4rem;
        margin-top: 1rem;
        padding: 0.5rem 1.5rem;
  
        font-weight: 300;
  
        background: var(--c_green);
        color: var(--c_white);
        
        &:focus {
          outline-color: var(--c_green-60);
        } 
      }
    }
    .privacy {
      h2 {
        display: flex;
        align-items: center;
        font-family: var(--f_open-sans);
        color: var(--c_green-44);
        margin-bottom: 0.25rem;
        font-size: 1.5rem;

        svg {
          margin-right: 0.5rem;
        }
      }
      p {
        color: var(--c_neutral-47);
      }
    }

    @media (min-width: theme('screens.xs')) {
      .addMortgageContainer {
        .addMortgageLarge {
          display: block;
        }
        .addMortgageSmall {
          display: none;
        }
      }
    }
  }
  @media (min-width: theme('screens.lg')) {
    .title {
      font-size: 2.25rem;
    }
    .titleDescription {
      font-size: 1.5rem;
    }
    .content {
      flex-direction: row-reverse;
      flex-wrap: nowrap;
      align-items: stretch;
      margin-top: 6rem;
      .editDetails {
        flex: 0 0 2;
        margin-right: 3rem;
      }
      .propertyAndPrivacyContainer {
        flex: 0 0 1;
        max-width: 23rem;
        .propertyAndPrivacy {
          position: sticky;
          top: 3rem;
          margin-bottom: 3rem;
          .propertyDetails {
            margin-bottom: 3rem;

            .helpBox {
              margin-top: 0;
            }
          }
          .privacy {
            display: block;
          }
        }
      }
      .privacy {
        display: none;
      }
    }
  }
  @media (min-width: theme('screens.md')) {
    .content {
      .financialAnalysis {
        .helpBox {
          display: flex;
        }
      }
    }
  }
}
