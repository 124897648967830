.alert {
  --c_alert-background: var(--c_gold-95);
  --c_alert-content: var(--c_orange-63); 
  
  display: flex;
  align-items: flex-start;
  grid-column: 1/-1;
  padding: 1rem;
  position: relative;

  background: var(--c_alert-background);

  strong {
    font-weight: 500;
  }

  .content {
    margin: 0 1.6rem 0 0.5rem;
    color: var(--c_alert-content);
    font-size: 0.85rem;
  }

  .close {
    position: absolute;
    right: 1.5rem;
  }
}

@media (min-width: theme('screens.sm')) {
  .alert {
    align-items: center;
    padding: 1.5rem;

    .content {
      margin-left: 0.5rem;
    }
  }
}
