.pageHeader {
  h1.head {
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 2rem;
    margin-bottom: 0.5rem;

    @media (min-width: theme('screens.lg')) {
      font-size: 2.25rem;
      line-height: 2.75rem;
    }

    &:not(:first-child) {
      padding-top: 1.5em;
      margin-top: 1.5em;
      border-top: 1px solid var(--c_neutral-86);
    }
  }

  p.subhead {
    font-size: 1rem;
    margin-top: 0.25rem;
    margin-bottom: 0;
    line-height: 1.5rem;
    font-weight: normal;

    @media (min-width: theme('screens.lg')) {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }
}
