.row{
  display: flex;
  flex-wrap: wrap;
  .input{
    flex: 0 0 100%;
    margin-bottom: 1.5rem;
    label{
      margin-bottom: 0.75rem;
    }

    .inputTooltip {
      display: inline;
      svg {
        display: inline;
        color: var(--c_neutral-86);
        fill: var(--c_neutral-86);
        margin-left: .25rem;
      }
    }

    input{
      display: block;
      width: 100%;
    }
  }

  .hiddenDiv{
    display: none;
  }
  
  input:focus{
    border-color: var(--c_green-44);
    outline-width: 0;
  }

  @media (min-width: theme('screens.lg')){
    margin: 0 -0.75rem 1.5rem;
    .input{
      flex: 1 1 0;
      margin: 0 0.75rem;
    }
    &.oneElementRow{
      .hiddenDiv{
        display: block;
      }
      .input{
        flex: 1 1 0;
      }
    }
  }
}

.actionsRow{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  .saveButton{
    :global(.spinner) {
      margin-left: .75rem;
      height: 1rem;
    }
    padding-top: 0.5rem;
    padding-bottom: 0.625rem;
    background-color: var(--c_green-44);
    color: var(--c_white);
  }
  .deleteButton{
    svg{
      color: var(--c_neutral-65);
      float: left;
    }
    span{
      color: var(--c_neutral-47);
    }
  }
  .short {
    display: inline;
  }
  .long {
    display: none;
  }

  @media (min-width: theme('screens.lg')) {
    .saveButton{
      font-size: 1.125rem;
      padding-top: 0.6rem;
      padding-bottom: 0.75rem;
    }
    .short {
      display: none;
    }
    .long {
      display: inline;
    }
  }
}
