.tooltipBase {
  max-width: 40vw;
}

.tooltip {
  height: 24px;
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 24px;

  svg {
    fill: theme('colors.realm-silver');
  }

  &:hover {
    cursor: default;

    svg {
      fill: theme('colors.realm-slate');
    }
  }
}

.overlay a {
  color: theme('colors.realm-gold');

  &:hover {
    color: theme('colors.realm-gold');
  }
}
