.card {
  position: relative;

  background: var(--c_white);
  border-radius: 1.5rem;
  padding: 2rem;
} 

@media (max-width: (theme('screens.md') - 1)) {
  .card {
    padding: 1rem;
  }
}
