.propertyDetails{
  position: relative;
  padding: 1.5rem;
  .avatar {
    object-fit:cover;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    margin: calc(-1.5rem - 40px) auto 1rem;
  }

  .address{
    text-align: center;
    font-size: 1.5rem;
    font-family: var(--f_open-sans);
    color: var(--c_neutral-4);
  }

  .cityStateZip{
    text-align: center;
    font-size: 1rem;
    font-family: var(--f_open-sans);
    color: var(--c_neutral-47);
    margin-bottom: 1.5rem;
  }

  .steps{
    color: var(--c_neutral-4);
    font-family: var(--f_open-sans);
    position: relative;
    width: calc(100% + 3rem);
    right: 1.5rem;
    margin-bottom: 1.5rem;
    border-radius: 0 0 1.5rem 1.5rem;
  }
  
  .explanation{
    display: none;
  }

  .link{
    display: none;
  }

  @media (min-width: theme('screens.lg')) {
    padding-bottom: 1.5rem;
    .steps{
      border-radius: 0;
    }
    .explanation{
      display: block;
      color: var(--c_neutral-4);
      font-size: 1.25rem;
      line-height: 1.2;
      font-family: var(--f_optima);
      margin-bottom: 0.75rem;
    }
    .link{
      display: inline-flex;
      align-content: center;
    }
  }
}
