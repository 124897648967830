.global-alert {
  &__alert {
    position: fixed;
    width: 500px;
    display: block;
    z-index: 1000;
    left: 50%;
    margin-left: -250px;
    transform: translateY(-500px);
    transition: transform 250ms ease-in-out;

    &--visible {
      transform: translateY(36px);
    }
  }

  .alert {
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.15);
  }
}
