.accessibleFunds {
  position: relative;
  div.toolTip {
    top: 0;
    right: 0;
  }
  .fundRows {
    --c_row-border: var(--c_neutral-86);
    display: flex;
    flex-direction: column;
    > div {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid var(--c_row-border);
      padding: 0.5rem 0 0.5rem 0;
      &.fields {
        padding-bottom: 1rem;
        div {
          display: none;
          &.keepDisplayed {
            display: block;
          }
        }
        color: var(--c_disabled);
      }
      > div:nth-child(1) {
        flex-basis: 100%;
        padding-bottom: 0.2rem;
      }
      > div:nth-child(2) {
        flex: 2 1 0;
        margin-right: 2rem;
      }
      > div:nth-child(3) {
        flex: 1 1 0;
      }
      .fieldName {
        color: var(--c_disabled);
      }
      div.funds {
        color: var(--c_green-44);
      }
    }
  }
  .alert {
    margin-top: 1.5rem;
    right: 1rem;
    width: calc(100% + 2rem);
  }
  .noDataAlert {
    margin-top: 0;
    bottom: 1rem;
    border-radius: 1.5rem 1.5rem 0 0;
  }
  .bottomLinksContainer {
    @media (min-width: theme('screens.md')) {
      display: flex;
      justify-content: space-between;
    }
  }
  .arrowLink {
    margin-top: 1rem;
  }
  .noDataArrowLink {
    margin-top: 0;
  }
  @media (min-width: theme('screens.md')) {
    div.toolTip {
      top: -1rem;
      right: -1rem;
    }
    .fundRows {
      > div {
        padding: 1rem 0 1rem 0;
        &.fields {
          padding: 0 0 0.5rem 0;
          > div {
            display: block;
          }
        }
        > div {
          display: block;
          .fieldName {
            display: none;
          }
        }
        > div:nth-child(1) {
          flex: 2.5 1 0;
        }
        > div:nth-child(2) {
          flex: 2 1 0;
        }
        > div:nth-child(3) {
          flex: 1 1 0;
        }
      }
    }
    .alert {
      right: 2rem;
      width: calc(100% + 4rem);
    }
    .noDataAlert {
      bottom: 2rem;
    }
  }
}
