a.arrowLink,
.arrowLink {
  display: inline-flex;
  align-content: center;
  font-weight: bold;

  color: var(--c_black);

  .arrow {
    flex: 0 0 auto;
    width: 1.5em;
    height: 1.5em;
    transform: rotateZ(-90deg);
    margin-right: 0.5em;
  }
  .content {
    flex: 1 1 auto;
  }
}

button.arrowLink {
  transition: color 0.3s;
}
