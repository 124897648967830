.content {
  --c_grey-out-text: var(--c_disabled);

  section {
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .nonTitleContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    .mainContent {
      .arrowLinkEquity {
        color: var(--c_white);
      }
      a:hover.arrowLinkEquity,
      button:hover.arrowLinkEquity {
        color: var(--c_green-23);
        text-decoration: underline;
      }
    }
    .contentChunk {
      margin-bottom: 3rem;
      .labelContainer {
        h2 {
          margin-bottom: 0.25rem;
          font-size: 1.5rem;
          color: var(--f_open-sans);
        }
        p {
          margin-bottom: 1.25rem;
          font-size: 1rem;
          color: var(--c_text-secondary);
        }
      }
      .equityOverview {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .sideContent {
      display: grid;
      gap: 3rem;

      .details {
        padding: 1.5rem 1rem 1.5rem 1rem;
      }
      // .reportUpsell{
      //   h2{
      //     padding-bottom: 0.5rem;
      //     border-bottom: 3px solid var(--c_black);
      //   }
      //   p{
      //     color: var(--c_neutral-4);
      //     font-family: var(--f_optima);
      //   }
      //   padding-bottom: 0;
      //   background-color: var(--c_blue-95);
      // }
    }
    .privacy {
      margin-bottom: 3rem;
      h2 {
        display: flex;
        align-items: center;
        font-family: var(--f_open-sans);
        color: var(--c_green-44);
        margin-bottom: 0.25rem;
        font-size: 1.5rem;

        svg {
          margin-right: 0.5rem;
        }
      }
      p {
        color: var(--c_neutral-47);
      }
    }
    .helpBox {
      grid-row: 1;

      display: none; // Not visible in mobile.
      flex-direction: column;
      align-items: center;
      width: 100%; 
      border-radius: 1.5rem;
  
      padding: 2rem;
  
      font-size: 1.125rem;
      text-align: center;

      background: var(--c_green-95);

      .advisorCta {
  
        border-radius: 4rem;
        margin-top: 1rem;
        padding: 0.5rem 1.5rem;
  
        font-weight: 300;
  
        background: var(--c_green);
        color: var(--c_white);
        
        &:focus {
          outline-color: var(--c_green-60);
        } 
      }
    }
  }
  @media (min-width: theme('screens.lg')) {
    section {
      flex-direction: row;
      margin: 0 26rem 3.76rem 0;
      h1 {
        margin-bottom: 0.25rem;
        font-size: 2.25rem;
      }
      p {
        font-size: 1.5rem;
      }
    }
    .nonTitleContent {
      flex-direction: row;
      align-items: flex-start;

      .mainContent {
        flex: 1 1 auto;
        margin-right: 3rem;
      }
      .sideContent {
        flex: 0 0 23rem;
        .helpBox {
          grid-row: auto;
        }
      }
    }
  }
  @media (min-width: theme('screens.md')) {
    .nonTitleContent {
      .sideContent {
        .details {
          padding: 1.5rem 2rem 1.5rem 2rem;
        }
        .helpBox {
          display: flex;
        }
      }
    }
  }
}
