.funkyCard {
  position: relative;
  background: var(--c_green);
  color: var(--c_white);
  border-radius: 1.5rem 1.5rem 2rem 2rem;
  // border-bottom-left-radius: 5rem;   // little green line shows up when this is same radius 
  // border-bottom-right-radius: 5rem;  // as body 

  .header {
    padding: 0.75rem 1rem;
    position: relative;
    letter-spacing: -0.0125rem;
    overflow: hidden;
    @media (min-width: theme('screens.lg')) {
      padding: 1.5rem;
    }
    h2.title {
      font-size: 1.5rem;
      color: var(--c_white)
    }
    h4.description {
      color: var(--c_white);
      opacity: 0.64;
    }
  }
  .patterns {
    border-radius: 1.5rem;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    svg {
      width: 100%;
      object-fit: cover;
      color: var(--c_black);  
      opacity: 0.12;
    }
  }
  .body {
    position: relative;
    border-radius: 1.5rem 1.5rem 1.5rem 1.5rem;
    background: var(--c_white);
    color: var(--c_black);
    padding: 1rem;
    @media (min-width: theme('screens.lg')) {
      padding: 2rem;
    }
  }
} 
