ol.bubbleList {
  list-style: none;
  text-align: left;
  padding: 1.5rem;
  padding-left:3rem;
  background: var(--c_gold-95);
  color: var(--c_black);
  counter-reset: bubbles;

  li {
    margin: 0.875rem 0;
    position: relative;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
    
    &::before {
      counter-increment: bubbles;         
      content: counter(bubbles); 
      top: .25rem;
      left: -1.5rem;
      border-radius: 50%;
      border: 1px solid var(--c_black);
      width: 1rem;
      height: 1rem;
      position: absolute;
      line-height: 0.875rem;
      text-align: center;
    }
  }
}
