@keyframes menuappear {
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

.menu {
  opacity: 0;
  transform: scaleY(0.9);
  transform-origin: top;
  z-index: 1050;
  min-width: 144px;
  animation: menuappear 150ms ease-out forwards;
}

.header {
  --c_logo: var(--c_brand);
  --c_nav-link: var(--c_text-secondary);
  --c_nav-link-active: var(--c_text);
  --c_user-background: var(--c_white);
  --c_user-icon: var(--c_green-23);

  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem;
  width: 100%;

  .profile {
    display: none;
  }

  nav {
    align-items: center;
    display: flex;

    .logo {
      align-self: flex-end;

      width: 83px;
      height: 34px;

      color: var(--c_logo);
      margin-right: 2rem;
      margin-bottom: -1px;
    }

    a.link {
      color: var(--c_nav-link);
      white-space: nowrap;
      margin-right: 1.25rem;
      @media (min-width: theme('screens.xl')) {
        margin-right: 2rem;
      }
    }
    a.active {
      color: var(--c_nav-link-active);
    }

    .navMobile {
      .menuIcon {
        height: 24px;
        margin-right: 1rem;
        text-indent: -9999px;
        width: 24px;
      }
    }

    .navDesktop {
      display: none;
    }
  }

  .menus {
    display: flex;
    gap: 1rem;

    .advisorCta {
      flex: 1 1 auto;
      align-self: stretch;

      display: none; // not visible on mobile.
      align-items: center;

      border-radius: 0.5rem;
      padding: 0.5em 0.5em;

      background: var(--c_white);
      color: var(--c_green);

      max-height: 44px;

      font-size: 0.5rem;
      line-height: 1.25em;
      font-weight: 300;
      text-align: right;

      outline-color: var(--c_green);

      svg {
        display: none;
        margin-right: 1rem;
      }
    }
  }

  .user {
    background: var(--c_user-background);
    align-items: center;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    height: 44px;
    justify-content: center;
    width: 44px;

    svg {
      color: var(--c_user-icon);
    }
  }
}

.propertyMenu {
  position: relative;

  .allProperties {
    display: none;
    opacity: 0;
    transition: opacity 0.2s;
  }

  &:hover {
    // Funky breakpoint for a funky world. See below.
    @media (max-width: 1309px) {
      position: static;
    }

    .allProperties {
      display: flex;
      opacity: 1;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 99;

      @media (max-width: 1309px) {
        top: 2rem;
        right: 2rem;
        left: unset;
        width: 20rem;
      }
    }
  }
}

.propertyMenuContainer {
  background-color: theme('colors.white');
  border-radius: 4px;
  box-shadow: 0 4px 16px 0 rgba(224, 212, 200, 0.32);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  overflow: hidden;
}

.dropdownArrow {
  position: absolute;
  right: 1rem;
  top: 50%;
  margin-top: -4px;

  polygon {
    fill: theme('colors.gray.400');
  }
}

.propertyMenuItem {
  color: theme('colors.black');
  display: flex;
  align-items: center;

  &:hover {
    color: theme('colors.black');
    opacity: 0.8;
    text-decoration: none;
  }
}

.propertyMenuItemImage {
  height: 3rem;
  width: 3rem;
  min-width: 3rem;
}

.propertyMenuItemLabel {
  padding: 0.75rem 3rem 0.75rem 1rem;
  white-space: nowrap;
  max-width: 13rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.viewAllProperties {
  padding: 1rem 1rem 1rem 4rem;
  color: var(--c_green);

  &:hover {
    color: var(--c_green-23);
    text-decoration: underline;
  }
}

// OK so basically at this point we're packing so much into the
// header that we need to do some pretty annoying micromanagement
// in order to keep things from breaking out or wrapping when we
// don't want it to. So we get very aggressive about defining
// measured breakpoints on (mostly) the advisorCta to prevent
// this. So the next set of media queries control that.
@media (min-width: 327px) {
  .header .menus .advisorCta {
    font-size: 0.75rem;
  }
}
@media (min-width: 369px) {
  .header .menus .advisorCta {
    font-size: 0.8rem;
  }
}
@media (min-width: 385px) {
  .header .menus .advisorCta {
    font-size: 0.9rem;
    padding: 0.5em 1em;
  }
}
@media (min-width: 490px) {
  .header .menus .advisorCta {
    font-size: 1rem;
    font-weight: 400;
  }
}
@media (min-width: theme('screens.md')) {
  .header {
    .menus {
      gap: 1rem;
      flex: 0 0 auto;

      .advisorCta {
        flex: 1 0 auto;
      }
    }
  }
}
@media (min-width: 785px) {
  .header .menus {
    .advisorCta {
      font-size: 1rem;
      font-weight: 400;
    }
  }
}
@media (min-width: 801px) {
  .header .menus {
    .advisorCta {
      svg {
        display: block;
      }
    }
  }
}
@media (min-width: 816px) {
  .header .menus {
    gap: 2rem;
  }
}
@media (max-width: 1309px) {
  .propertyMenuItem {
    width: 6rem;
  }

  .propertyMenuItemLabel {
    display: none;
  }

  .allProperties {
    .propertyMenuItemLabel {
      display: flex;
    }
    .propertyMenuItem {
      width: auto;
    }
  }
}
// And eventually hidden. All well.
@media (max-width: 1129px) {
  .propertyMenu {
    display: none;
  }
}
// That should be the end of the fiddily stuff.

@media (min-width: theme('screens.lg')) {
  .header {
    padding: 2rem 1rem;

    .profile {
      display: block;
    } /* Turn on desktop dropdown */
    .profileMobile {
      display: none;
    } /* Turn off mobile dropdown */
    nav {
      .navMobile {
        display: none;
      }
      .navDesktop {
        display: block;
      }
    }
    .menus .advisorCta {
      display: flex;
    }
  }
}

@media (min-width: theme('screens.xl')) {
  .header {
    padding: 2rem;
  }
}
